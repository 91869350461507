import { createContext, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useState from 'react-usestateref';
import { getComponents } from '../helpers/events';
import { token } from '../constants';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthInProgress, setIsAuthInProgress] = useState(true);
    const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem(token));
    const [_c, setComponents, componentsRef] = useState({});
    const [_p, setPermissions, permissionsRef] = useState({});

    const login = (auth) => {
        localStorage.setItem(token, auth.token);
        localStorage.setItem('username', auth.username);

        setComponents(auth.components);
        setPermissions(auth.permissions)
        setIsAuthInProgress(false);
        setAuthenticated(true);

        return componentsRef.current;
    }

    const logout = () => {
        localStorage.setItem('filterState', JSON.stringify({ userFilter: false, filter: '' }));
        localStorage.removeItem(token);
        localStorage.removeItem('username');

        setAuthenticated(false);
        setIsAuthInProgress(true);
    }

    const componentFlags = componentsRef.current;

    const isFeatureEnabled = (feature, components = null) => {
        const featureMap = new Map([
            ['PAPERMILL_CHECK', 'PMChk'],
            ['WATCHLIST', 'WTLst'],
            ['CACTUS', 'cactusTool']
        ]);
        const currentComponents = components ?? componentsRef.current;

        return currentComponents?.[featureMap.get(feature)] || false;
    }

    const isPermissionGranted = (permission) => {
        return permissionsRef.current[permission] || false;
    }

    const setComponentsState = async () => {
        try {
            if (localStorage.getItem(token)) {
                const response = await getComponents();
                const { components = {}, permissions = {} } = response?.data;
                setPermissions(permissions);
                setComponents(components);
                setIsAuthInProgress(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setComponentsState();
        window.addEventListener('force-logout', logout);

        return () => window.removeEventListener('force-logout', logout);
    }, []);

    return (
        <AuthContext.Provider value={{ login, logout, isAuthInProgress, isAuthenticated, isFeatureEnabled, isPermissionGranted, componentFlags}}>
            {children}
        </AuthContext.Provider>
    )
}

export const RequireAuth = ({ children, redirectTo = '/login', feature }) => {
    const { isAuthInProgress, isAuthenticated, isFeatureEnabled } = useAuth();

    if (isAuthenticated && isAuthInProgress) {
        return <></>;
    }

    const isAllowed = feature !== undefined ? isFeatureEnabled(feature) : true;
    return (
        isAuthenticated && isAllowed
            ? children
            : <Navigate to={redirectTo} />
    )
}

export default AuthProvider;