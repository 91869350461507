import React, { useState } from "react";
import { MDBCard, MDBCollapse, MDBIcon } from "mdb-react-ui-kit";
import { Popover } from "stm-frontend-library";

const InfoPopover = ({ title, content }) => {
  return (
    <Popover popoverHeader={title} popoverBody={content}>
      <span>
        <i className="fa fa-info-circle fa-stack-1x info_circle ms-1"></i>
      </span>
    </Popover>
  );
};

const CardHeader = ({ title }) => (
  <div className="card-header p-3 pt-4 pb-3 border-0">
    <div className="d-flex justify-content-between">
      <h5 className="card-title fw-normal">{title}</h5>
    </div>
  </div>
);

const DataList = ({ title, dataItems, hasData, renderItem, type, isLast }) => (
  <div className={`mt-1 ${isLast ? "mb-1" : "mb-3"}`}>
    <p className="fw-bold mb-1">{title}:</p>
    {dataItems?.length
      ? dataItems.map((item, index) => renderItem(item, index, type))
      : hasData
        ? "None found"
        : "N/A"}
  </div>
);

export const AUTHORSHIP_LABELS = {
  author_affiliation_relevance: {
    label: "Author affiliation relevance",
    popoverText:
      "Affiliation relevance to the field of study discussed in the manuscript.",
  },
  author_location: {
    label: "Author geographical distribution",
    popoverText: "Authors’ affiliations from 3 or more countries.",
  },
};

export const REFERENCE_LABELS = {
  citation_cartel_reference: {
    label: "Citation cartel",
    popoverText:
      "Excessive citations to one person or group of people from the same lab.",
  },
  self_citation_reference: {
    label: "Self citation",
    popoverText:
      "Excessive citation to papers by the authors of the manuscript.",
  },
  age_of_reference: {
    label: "Age of reference",
    popoverText: "Too many old references cited.",
  },
  reference_cited: {
    label: "Reference citation",
    popoverText: "Listed references not cited in the manuscript.",
  },
  unrelated_reference: {
    label: "Unrelated references",
    popoverText:
      "List of references that are not relevant to the field of study discussed in the manuscript.",
  },
};

const determineCardColor = (conditions) =>
  conditions.some((cond) => cond) ? "bg-danger" : "bg-success";

const determineResultColor = (value) => {
  const colorMap = {
    PASSED: "text-success",
    WARNING: "text-warning",
    CRITICAL: "text-danger",
  };
  return colorMap[value] || "";
};

const CactusCard = ({ cactusTool }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSectionExpand = (index) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderExpandableContent = (
    { extKey, checkLabel, result, description, additionalDescription },
    index,
    contentType
  ) => {
    const LABELS =
      contentType === "authorship" ? AUTHORSHIP_LABELS : REFERENCE_LABELS;
    const { label, popoverText } = LABELS[extKey] || checkLabel;

    const { summary = undefined, additionalData = undefined } =
      additionalDescription ?? {};

    return (
      <div className="my-2" key={index}>
        <hr className="hr" />
        <div className={`d-flex justify-content-between bg-opacity-25`}>
          <div>
            {`${label}: `}
            <b className={determineResultColor(result)}>{result}</b>
            {description && ` - ${description}`}
          </div>
          <InfoPopover title={label} content={popoverText} />
        </div>

        <div className="d-flex justify-content-between align-items-center">
          {summary && <p>{summary}</p>}
          {additionalData && (
            <MDBIcon
              icon={expandedSections[index] ? "chevron-up" : "chevron-down"}
              onClick={() => toggleSectionExpand(index)}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>

        <MDBCollapse show={expandedSections[index]} className="mt-2">
          {additionalData && (
            <div>
              {Object.entries(additionalData).map(([key, data], i) => (
                <div key={i}>
                  <span>{key}</span>
                  <ul>
                    {data.map((item, j) => (
                      <li key={j}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </MDBCollapse>
      </div>
    );
  };

  return (
    <MDBCard
      className={`mt-4 bg-opacity-25 ${determineCardColor([
        cactusTool?.authorship?.some((x) => x.result && x.result !== "PASSED"),
        cactusTool?.references?.some((x) => x.result && x.result !== "PASSED"),
      ])}`}
    >
      <CardHeader title="Cactus Integrity Checks" />
      <div className="card-body p-3 bg-white bg-opacity-25">
        <DataList
          title="Authorship Issues"
          dataItems={cactusTool?.authorship}
          hasData={cactusTool?.authorship}
          renderItem={renderExpandableContent}
          type="authorship"
        />
        <DataList
          title="Reference Issues"
          dataItems={cactusTool?.references}
          hasData={cactusTool?.references}
          renderItem={renderExpandableContent}
          type="references"
          isLast={true}
        />
      </div>
    </MDBCard>
  );
};

export default CactusCard;
