import { useRef } from "react";
import { MDBBtn, MDBIcon, MDBModalFooter, MDBTypography } from "mdb-react-ui-kit";
import { exportWatchlist, showErrorToast, showSuccessToast } from "../../../helpers";
import { ModalWrapper } from "../../../components/ModalWrapper"
import { useLoader } from "stm-frontend-library";

const ExportModal = ({ isOpen, onClose, suspectsType }) => {
    const handleCloseModal = () => onClose();

    const anchorRef = useRef(null);
    const { showLoader, hideLoader, isLoading } = useLoader();

    const handleExport = async () => {
        showLoader();
        try {
            const response = await exportWatchlist(suspectsType);

            const fileUrl = response.data;
            anchorRef.current.href = fileUrl;
            anchorRef.current.target = '_blank';
            anchorRef.current.download = 'downloaded_file';
            anchorRef.current.click();

            showSuccessToast();
            handleCloseModal();
        } catch (error) {
            showErrorToast();
        } finally {
            hideLoader();
        }
    };

    return (
        <ModalWrapper
            title={'Watchlist'}
            isOpen={isOpen}
            onClose={handleCloseModal}
        >
            <MDBTypography>
                Please note - This sheet and its contents are confidential and should not be circulated to any person outside of STM or the Watchlist who does not have a specific need to know.
            </MDBTypography>
            <MDBTypography>
                However, we cannot guarantee that this sheet is legally privileged, meaning that it may be disclosed in court proceedings. Please complete this sheet under the assumption that it may be seen by all parties involved in this matter at a later date.
            </MDBTypography>
            <MDBTypography>
                As such, we request that you keep the information in this sheet brief and factual. Please do not include any judgement or opinions about the matter, including about any organisations or individuals involved (the Editor, Authors, Complainants etc). Please limit any personal data, for example names or contact details, of any individuals involved in the sheet itself, and only include personal data that is absolutely necessary to the matter.
            </MDBTypography>
            <MDBModalFooter className="justify-content-center">
                <MDBBtn
                    color="secondary"
                    size="md"
                    onClick={handleExport}
                    disabled={isLoading}
                >
                    <MDBIcon icon="fas fa-file-export" className="me-2" /> Export
                </MDBBtn>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a ref={anchorRef} href="#" role="button" style={{ display: 'none' }}>
                    Download File
                </a>
            </MDBModalFooter>
        </ModalWrapper>
    )
}

export { ExportModal }
