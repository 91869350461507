import { isNumeric, isObject, isEmptyObject } from "./helpers";
import { decode } from "html-entities";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  AUTHORSHIP_LABELS,
  REFERENCE_LABELS,
} from "../components/Modal/tabs/components/CactusCard";
import { formatKey, capitalize } from "./helpers";

const formatArray = (arr, formatter = (item) => item) =>
  !arr ? "N/A" :
    Array.isArray(arr) && arr.length ? arr.map(formatter).join("\n") : "None found";

const formatValue = (value, defaultValue = "N/A") =>
  value !== null && value !== undefined ? value : defaultValue;

const formatNumeric = (value) => value !== null ? `${value}` : "-";

const formatHeader = ({ documentName, externalPublisherId, documentTitle, userEmail, manuscriptId }) => ({
  "Document name": documentName || externalPublisherId,
  "Document title": documentTitle,
  "User ID": userEmail ?? "",
  "Manuscript ID": manuscriptId ?? "",
});

const formatDOI = ({ refIdentifiers: { extracted, invalid, parsed, unidentified } = {} }) => ({
  "Total number of references listed in manuscript": formatNumeric(parsed),
  "From which DOIs were listed or could be retrieved": formatNumeric(extracted),
  "Number of DOIs in the reference list that do not exist (via check DOI.org)":
    invalid?.length > 0 ? `${invalid.length}` : formatNumeric(invalid?.length),
  "Non-retrieved reference titles": unidentified?.length
    ? unidentified.map(item => item.title)
    : "-",
});

const formatRetractions = ({ retractionWatch }) => ({
  "Retraction Watch:": formatArray(retractionWatch,
    item => `${item.DOI} ${item.title}`
  ),
});

const formatPubPeer = ({ feetOfClayRatio: foc }) => {
  const { retracted: fRetracted, details: fDetails } = foc || {};
  return {
    "PubPeer retractions": formatArray(fRetracted),
    "PubPeer mentions:": formatArray(fDetails,
      item => `${item.doi} [${item.comments}]`
    ),
  };
};

const formatSimilarity = ({ clearSkiesStatus: similarity }) => ({
  "Papermill similarity": similarity !== null
    ? (!isEmptyObject(similarity) ? capitalize(similarity) : "None")
    : "N/A",
});

function formatWordFileProperties(items) {
  return items.map(item => {
    const formattedMessage = item.message.replace(/\s*\(.*?\)/g, '').trim();

    const detailsEntries = Object.entries(item.details || {});
    const formattedDetails = detailsEntries
      .map(([key, value]) => `${formatKey(key)}: ${value}`)
      .join('; ');

    return {
      ...item,
      message: formattedMessage,
      details: formattedDetails,
    };
  });
}

const formatText = ({ signals, gptDetectorScore: unnatural, genAIResponse, wordFileProperties }) => ({
  "Tortured phrases": formatArray(signals, item => item.pattern),
  "Unnatural text": isNumeric(unnatural) ? `${unnatural}%` : "N/A",
  "GenAI response": formatArray(genAIResponse, item => item.pattern),
  "Word file metadata": formatWordFileProperties(wordFileProperties).map(item =>
    `${item.message}${item.details ? '\n' + item.details : ''}`
  ).join('\n\n'),
});

const formatCactusSection = (items, labels) =>
  formatArray(items, ({ extKey, checkLabel, result, description, additionalDescription }) => {
    const label = (labels[extKey] || checkLabel)?.label || "Unknown";
    const { summary, additionalData } = additionalDescription ?? {};

    return [
      `Label: ${label}`,
      `Result: ${result || "N/A"}`,
      `Description: ${description || "N/A"}`,
      summary ? `Summary: ${summary}` : null,
      additionalData ? `Additional Data: ${Object.entries(additionalData)
        .map(([key, data]) => `${key}: ${data.join(", ")}`)
        .join("\n")}` : null,
    ].filter(Boolean).join("\n");
  });

const formatCactus = ({ cactusTool }) => ({
  Authorship: cactusTool?.authorship?.length
    ? formatCactusSection(cactusTool.authorship, AUTHORSHIP_LABELS)
    : "No authorship issues found",
  References: cactusTool?.references?.length
    ? formatCactusSection(cactusTool.references, REFERENCE_LABELS)
    : "No reference issues found",
});

const formatMetadata = (suspects, components) => {
  const metadataConfig = {
    watchlistFakeAffiliationNames: ["Fake affiliation names", suspects.affiliations],
    watchlistFakeNamesOrEmailAddresses: ["Fake names or e-mail addresses", suspects.identities],
    watchlistFakeEmailDomains: ["Fake e-mail domains", suspects.emails],
    watchlistDisposableEmailDomains: ["Disposable e-mail domains", suspects.tempmails],
    watchlistBadActors: ["Bad actors", suspects.actors],
    watchlistSuspectArticles: ["Suspect articles", suspects.articles],
    watchlistManuscriptsOfferedForSale: ["Manuscripts offered for sale", suspects.manuscripts],
    watchlistMetadataSuspects: ["Metadata suspects", suspects.metadata],
  };

  return Object.entries(metadataConfig).reduce((acc, [key, [label, items]]) => {
    if (components[key]) {
      acc[label] = formatMetadataCategory(items);
    }
    return acc;
  }, {});
};

function formatMetadataCategory(items) {
  if (!items || !Array.isArray(items)) {
    return "N/A";
  }

  if (!items?.length) {
    return "None found";
  }

  return items
    .map((item) => {
      if (isObject(item)) {
        const { value, providedby } = item;
        return providedby ? `${value}\nProvided by ${providedby}` : value;
      }
      return item;
    })
    .join("\n\n");
}

export const formatEvent = (event, components) => {
  return {
    header: formatHeader(event),
    doi: formatDOI(event),
    retractions: formatRetractions(event),
    pubpeer: formatPubPeer(event),
    similarity: formatSimilarity(event),
    text: formatText(event),
    metadata: formatMetadata(event.suspects || {}, components),
    cactus: formatCactus(event),
  };
};

export const genReport = async (event, components) => {
  const doc = new jsPDF();
  let formatted = formatEvent(event, components);

  const autoTableStyle = {
    columnStyles: { 0: { cellWidth: 70 }, 1: { cellWidth: "auto" } },
    bodyStyles: {
      valign: "middle",
      fillColor: [236, 236, 236],
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255],
    },
  };

  doc.setFontSize(16).text("Report", 15, 15);
  let body = tableBody(formatted.header);
  doc.autoTable({
    ...autoTableStyle,
    startY: 20,
    body,
  });

  let finalY = doc.previousAutoTable.finalY;
  doc.setFontSize(12).text("PubPeer", 15, finalY + 10);
  body = tableBody(formatted.pubpeer);
  doc.autoTable({
    ...autoTableStyle,
    startY: finalY + 15,
    body,
  });

  finalY = doc.previousAutoTable.finalY;
  doc.setFontSize(12).text("ClearSkies Papermill Alarm: Public", 15, finalY + 10);
  body = tableBody(formatted.similarity);
  doc.autoTable({
    ...autoTableStyle,
    startY: finalY + 15,
    body,
  });

  if (components?.cactusTool) {
    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text("Cactus Integrity Checks", 15, finalY + 10);
    body = tableBody(formatted.cactus);
    doc.autoTable({
      ...autoTableStyle,
      startY: finalY + 15,
      body,
    });
  }

  finalY = doc.previousAutoTable.finalY;
  doc.setFontSize(12).text("Reference analysis", 15, finalY + 10);
  finalY = doc.previousAutoTable.finalY;
  body = tableBody(formatted.doi);
  doc.autoTable({
    ...autoTableStyle,
    startY: finalY + 15,
    body,
  });

  finalY = doc.previousAutoTable.finalY;
  body = tableBody(formatted.retractions);
  doc.autoTable({
    ...autoTableStyle,
    startY: finalY + 3,
    body,
  });

  finalY = doc.previousAutoTable.finalY;
  doc.setFontSize(12).text("Text analysis", 15, finalY + 10);
  body = tableBody(formatted.text);
  doc.autoTable({
    ...autoTableStyle,
    startY: finalY + 15,
    body,
  });

  finalY = doc.previousAutoTable.finalY;
  doc.setFontSize(12).text("Watchlist", 15, finalY + 10);
  body = tableBody(formatted.metadata);
  doc.autoTable({
    ...autoTableStyle,
    startY: finalY + 15,
    body,
  });

  const blob = new Blob([doc.output()], { type: "application/pdf" });
  return blob;
};

const tableBody = (obj) => {
  let rows = Object.entries(obj);
  return rows.map((row) => {
    if (Array.isArray(row[1])) {
      row[1] = row[1].join("\n");
    }
    row[1] = decode(row[1]);
    return row;
  });
};

