import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { AuthProvider, RequireAuth } from "./components/Auth";
import { ContextWrapper } from "./components/AppContext";
import Logo from "./components/Logo";
import Login from "./pages/login/Login";
import Navbar from "./components/Navbar";
import PapermillCheck from "./pages/papermill-check/PapermillCheck";
import WatchList from "./pages/watch-list/WatchListPage";
import Profile from "./pages/profile/Profile";
import WatchListImport from "./pages/watch-list/WatchListImportPage";
import CookiePolicy from "./pages/cookie-policy/CookiePolicy";

const AuthLayout = () => {
  return (
    <>
      <Logo />
      <Outlet />
    </>
  );
};

const MainLayout = () => {
  return (
    <>
      <Logo />
      <Navbar />
      <Outlet />
    </>
  );
};

const Layout = () => {
  return (
    <AuthProvider>
      <ContextWrapper>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            <Route path="*" element={<Login />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="/papermill-check"
              element={
                <RequireAuth feature={"PAPERMILL_CHECK"}>
                  <PapermillCheck />
                </RequireAuth>
              }
            />
            <Route
              path="/watchlist"
              element={
                <RequireAuth feature={"WATCHLIST"}>
                  <WatchList />
                </RequireAuth>
              }
            />
            <Route
              path="/watchlist-import"
              element={
                <RequireAuth feature={"WATCHLIST"}>
                  <WatchListImport />
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </ContextWrapper>
    </AuthProvider>
  );
};

export default Layout;
