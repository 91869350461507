import React from "react";
import { useDropzone } from "react-dropzone";
import { MDBIcon } from "mdb-react-ui-kit";
import styled from "styled-components";
import { showErrorToast } from "../../helpers";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-width: 2px;
  border-radius: 4px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border 0.12s ease-in-out;
  color: #757575;
`;

const MAX_FILE_UPLOAD_SIZE = 10485760; // 10MB in bytes

const UploadDropzone = ({ onFileSubmit }) => {
  const handleOnDropAccepted = (files) => onFileSubmit(files);

  const handleOnDropRejected = (rejectedFiles) => {
    const errorMessages = rejectedFiles.map((file) => {
      if (file.errors.some((error) => error.code === "file-invalid-type")) {
        return `File "${file.file.name}" is not a supported type. Allowed formats: .doc, .docx, .pdf, .zip`;
      }
      if (file.errors.some((error) => error.code === "file-too-large")) {
        return `File "${file.file.name}" exceeds the maximum size limit of ${
          MAX_FILE_UPLOAD_SIZE / (1024 * 1024)
        } MB.`;
      }
      return `File "${file.file.name}" could not be uploaded.`;
    });

    showErrorToast(errorMessages.join("\n"));
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [],
        "application/pdf": [],
        "application/x-zip-compressed": [],
      },
      maxSize: MAX_FILE_UPLOAD_SIZE,
      onDropAccepted: handleOnDropAccepted,
      onDropRejected: handleOnDropRejected,
    });

  return (
    <div>
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <div className="d-flex align-items-center">
          <MDBIcon far icon="file" size="2x" className="link-info me-3" />
          <p className="m-auto">
            Drag & drop files here, or click to select files
          </p>
        </div>
      </Container>
    </div>
  );
};

export default UploadDropzone;
