import { axiosInstance } from "./axiosSetup";
import axios from "axios";
import { handleErrors } from "./handleErrors";

export const updatePassword = async (userId, data) => {
  return axiosInstance.patch(`admin/user/${userId}`, data);
};

export const getComponents = async () => {
  return axiosInstance.get("/user");
};

export const getEvents = async (
  offset,
  perPage,
  search,
  filter,
  userFilter
) => {
  return axiosInstance.get(
    `papermill/events?startrow=${offset}&maxrows=${perPage}&searchq=${encodeURIComponent(
      search
    )}&filter=${filter}&perUser=${userFilter ? "true" : "false"}`
  );
};

export const getUserEvents = async (offset, perPage, search, filter) => {
  return axiosInstance.get(
    `papermill/events-per-user?startrow=${offset}&maxrows=${perPage}&searchq=${encodeURIComponent(
      search
    )}&filter=${filter}`
  );
};

export const uploadFile = async (file) => {
  try {
    const response = await axiosInstance.post("papermill/initiate", {
      document: file.name,
    });
    const { s3PutObjectUrl } = response.data;
    await axios.put(s3PutObjectUrl, file);
    return true;
  } catch (error) {
    handleErrors(error);
    throw error;
  }
};

export const exportEvents = async () => {
  return axiosInstance.get("papermill/events/export");
};

export const deleteEvent = async (id) => {
  return axiosInstance.delete(`papermill/event/${id}`);
};
